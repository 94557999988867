import React from 'react';
import {Link} from "../../../../constants/interfaces";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./styles.scss";

export interface IProps {
    link: Link;
}

export default function LandingPageLinkChip(props: IProps) {
    const { link } = props;

    return (
        <div className="landingPageLinkChip__container">
            <a href={link.link} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className="inline-block align-top" icon={link.type.iconDefinition}/>
            </a>
        </div>
    );
}
