import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import CompanyCard from "../CompanyCard";
import {Grid} from "@material-ui/core";
import {jobExperiences} from "../../../../constants/data/jobExperiences";

export default function WorkHistory() {
    const horizontalOffset: string = "0";

    function generateJobExperienceCards() {
        const jobExperienceCards: React.ReactNode[] = [];

        for (let i = 0; i < jobExperiences.length; i++) {
            const isLastItem: boolean = i === jobExperiences.length - 1;
            jobExperienceCards.push(<CompanyCard jobExperience={jobExperiences[i]} isLastItem={isLastItem} horizontalOffset={horizontalOffset}/>);
        }

        return(jobExperienceCards);
    }

    return (
        <div>
            <Timeline align="left">
                <Grid container>
                    <Grid item xs={10}>
                        {generateJobExperienceCards()}
                    </Grid>
                </Grid>
            </Timeline>
        </div>
    );
}