import React from 'react';
import "./styles.scss";

export interface IProps {
    id?: string;
    title: string;
}

export default function SectionTitle(props: IProps) {
    const { id, title } = props;

    const currId = id ?? "";

    return (
        <h2 id={currId} className="sectionTitle__title">{title}</h2>
    );
}
