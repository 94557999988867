export const LinkTypes = {
    GITHUB: {
        name: "GitHub Repo",
        iconDefinition: ["fab", "github"],
    },
    LINKEDIN: {
        name: "LinkedIn",
        iconDefinition: ["fab", "linkedin"],
    },
    YOUTUBE: {
        name: "YouTube",
        iconDefinition: ["fab", "youtube"],
    },
    CUSTOM: {
        name: "External Site",
        iconDefinition: ["fas", "link"],
    },
}