import React, {useEffect, useState} from "react";
import {GalleryItem, Project} from "../../../../constants/interfaces";
import {GalleryItemTypes} from "../../../../constants/enums/galleryItemTypes"
import {Box} from "@material-ui/core";
import "./styles.scss";
import {ImageSrcs} from "../../../../constants/imageSrcs";

export interface IProps {
    project: Project
}

export default function ProjectGallery(props: IProps) {
    const { project } = props;
    const [ selectedGalleryItemIndex, setSelectedGalleryItemIndex ] = useState(0);
    const [ selectedGalleryItem, setSelectedGalleryItem ] = useState(project.galleryItems[selectedGalleryItemIndex]);
    const [ galleryItemWidth, setGalleryItemWidth ] = useState("100%");
    const [ galleryItemHeight, setGalleryItemHeight ] = useState("100%");

    function changeFeaturedProject(selectedGalleryItemIndex: number) {
        setSelectedGalleryItemIndex(selectedGalleryItemIndex);
        setSelectedGalleryItem(project.galleryItems[selectedGalleryItemIndex]);
    }

    function createProjectThumbnails(selectedIndex: number) {
        const projectThumbnails: React.ReactNode[] = [];
        project.galleryItems.forEach((galleryItem: GalleryItem, index) => {
            const thumbnailSrc: string = galleryItem.thumbnail !== "" ? galleryItem.thumbnail : window.location.origin + ImageSrcs.defaults.liveSample;

            projectThumbnails.push(<div onClick={() => { changeFeaturedProject(index); }}>
                <img
                    className={`thumbnail ${index === selectedIndex ? "item-selected" : "item-unselected"}`}
                    src={thumbnailSrc}
                    alt={galleryItem.description}
                    style={{height: "50px"}}
                />
            </div>);
        });
        return projectThumbnails;
    }

    useEffect(() => {
        setGalleryItemWidth(selectedGalleryItem.width ? selectedGalleryItem.width + "px" : "100%");
        setGalleryItemHeight(selectedGalleryItem.height ? selectedGalleryItem.height + "px" : "100%");
    }, [selectedGalleryItem]);

    return (
        <div>
            <Box
                className="project-gallery"
                width={selectedGalleryItem.type === GalleryItemTypes.HTMLCONTENT ? 1 : 0.8}
                style={{margin: selectedGalleryItem.type === GalleryItemTypes.IFRAME ? "" : "auto"}}
            >
                <div className="project-gallery-featured" style={{height: selectedGalleryItem.height ? (selectedGalleryItem.iframeZoomOut ? 0.75 : 1) * selectedGalleryItem.height : "100%"}}>
                    {
                        selectedGalleryItem.type === GalleryItemTypes.IFRAME &&
                            <iframe
                                id="gallery-iframe"
                                className={`project-item ${selectedGalleryItem.iframeZoomOut ? "content-zoomOut" : ""}`}
                                title={project.title}
                                src={selectedGalleryItem.content}
                                style={{
                                    width: galleryItemWidth,
                                    height: galleryItemHeight
                                }}
                            />
                    }
                    {
                        selectedGalleryItem.type === GalleryItemTypes.IMAGE &&
                            <img
                                className="project-item"
                                src={selectedGalleryItem.content}
                                alt={project.title}
                                style={{
                                    height: galleryItemHeight,
                                }}
                            />
                    }
                    {
                        selectedGalleryItem.type === GalleryItemTypes.HTMLCONTENT && selectedGalleryItem.content
                    }
                </div>
            </Box>
            <p className="text-center pt-2">{selectedGalleryItem.description}</p>
            <div className="project-gallery-thumbnails flex justify-center">
                { project.galleryItems.length > 1 && createProjectThumbnails(selectedGalleryItemIndex) }
            </div>
        </div>
    );
}