export function hackathonSimulator2021_descriptionContent() {
    return (
        <div>
            <p>Because COVID kept us at home this year, I decided to make a game to relive the in-person experience. This is quite possibly the stupidest project I've ever made - and I made titration simulator.</p>
            <p>This was my first project working with Unity, so please excuse the unpolished look.</p>
            <p><b>Swag Lineup:</b> Click a booth to line up for some swag! Then wait... Yeah, that's the game. Get swag from all 3 booths to win.</p>
            <p><b>Coding Simulator:</b> Hit random keys on your keyboard and watch code appear. Because that's how coding works.</p>
            <p><b>Last Minute Devpost:</b> The yearly tradition of hackathons is always to leave the project submission until the last minute. Fill out the submission form as fast as you can!</p>
        </div>
    );
}