import React from "react";
import { Grid } from '@material-ui/core';
import {SectionContentRow} from "../../../../constants/interfaces";
import "./styles.scss";
import SectionTitle from "../SectionTitle";

export interface ContentRowIProps {
    row: SectionContentRow;
}

export interface IProps {
    id: string;
    title: string;
    contentRows: SectionContentRow[];
}

const ContentRow = (props: ContentRowIProps) => {
    const { row } = props;
    const isImgPresent: boolean = !!row.imgSrc && row.imgSrc !== "";

    function dateRangeString(startDate: Date, endDate: Date) {
        const currDate: Date = new Date();
        if (startDate.getFullYear() === endDate.getFullYear()) return(startDate.getFullYear().toString());

        const startYear: string = startDate.getFullYear().toString();
        if (currDate.getFullYear() < endDate.getFullYear()) return(`${startYear} - Present`);
        return(`${startYear} - ${endDate.getFullYear().toString()}`);
    }

    return (
        <div className="sectionContent__row">
            <Grid container>
                {
                    isImgPresent &&
                        <Grid item xs={1}>
                            <img
                                className="sectionContent__img"
                                src={row.imgSrc}
                                alt={row.title}
                                style={{
                                    width: "104px",
                                    paddingRight: "10px",
                                }}
                            />
                        </Grid>
                }
                <Grid item xs={isImgPresent ? 8 : 9}>
                    <h3 className="sectionContent__title">{row.title}</h3>
                    {row.tagline && <h4 className="sectionContent__tagline">{row.tagline}</h4>}
                    <p className="sectionContent__description">{row.description}</p>
                </Grid>
                <Grid className="sectionContent__date" item xs={2}>
                    <div>{dateRangeString(row.startDate, row.endDate)}</div>
                </Grid>
            </Grid>
        </div>
    );
};

export default function Section(props: IProps) {
    const { id, title, contentRows } = props;

    function getContentRows() {
        const outputRows: React.ReactNode[] = [];
        contentRows.forEach((row: SectionContentRow) => {
            outputRows.push(<ContentRow row={row}/>);
        });
        return outputRows;
    }

    return (
        <div className="sectionContent__container">
            <div id={id}/>
            <div className="sectionContent__content">
                <SectionTitle title={title}/>
                {getContentRows()}
            </div>
        </div>
    );
}