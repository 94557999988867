import React, {useEffect, useState} from "react";
import {Dialog, Grid} from "@material-ui/core";
import {Project} from "./constants/interfaces";
import ProjectsGrid from "./sections/projects/components/ProjectsGrid";
import ProjectPopover from "./sections/projects/components/ProjectPopover";
import { emptyProject, projects } from "./constants/data/projects";
import WorkHistory from "./sections/projects/components/WorkHistory";
import './App.css';
import LandingPage from "./sections/projects/components/LandingPage";
import NavigationSidebar from "./sections/projects/components/NavigationSidebar";
import Section from "./sections/projects/components/SectionContent";
import {ImageSrcs} from "./constants/imageSrcs";
import SectionTitle from "./sections/projects/components/SectionTitle";
import {LinkTypes} from "./constants/enums/linkTypes";

function App() {
    const [ isProjectDialogOpen, setIsProjectDialogOpen ] = useState(false);
    const [ selectedProject, setSelectedProject ] = useState(emptyProject);
    const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
    const [ showSidebar, setShowSidebar ] = useState(shouldShowSidebar());

    function openProjectDialog(project: Project) {
        setSelectedProject(project);
        setIsProjectDialogOpen(true);
    }

    function closeProjectDialog() {
        setIsProjectDialogOpen(false);
    }

    function shouldShowSidebar() {
        return windowWidth > 1000;
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        setShowSidebar(shouldShowSidebar());
        // eslint-disable-next-line
    }, [windowWidth]);

    return (
        <div className="App">
            <header className="App-header">
                <meta name="referrer" content="strict-origin" />
            </header>
            <body className="App-body">
                <Grid container>
                    {
                        showSidebar
                            ? <Grid item xs={2}>
                                <div className="App-sidebar">
                                    <NavigationSidebar links={[
                                        {
                                            linkName: "Experience",
                                            linkSrc: "work-experience",
                                        },
                                        {
                                            linkName: "Projects",
                                            linkSrc: "projects",
                                        },
                                        {
                                            linkName: "Education",
                                            linkSrc: "education",
                                        },
                                        {
                                            linkName: "Awards",
                                            linkSrc: "awards",
                                        },
                                    ]}/>
                                </div>
                            </Grid>
                            : <div style={{paddingRight: "15px"}}/>
                    }
                    <Grid className="App-content" item xs={10}>
                        <LandingPage
                            id="main"
                            firstName="Andrew"
                            lastName="Tong"
                            programName="UBC COMPUTER SCIENCE"
                            startDate={new Date(2017, 9)}
                            endDate={new Date(2022, 5)}
                            blurb="Thanks for taking the time to view my website! I am a computer science student attending the University of British Columbia interested in web development and game design."
                            links={[
                                {
                                    type: LinkTypes.GITHUB,
                                    link: "https://github.com/andrewtong0",
                                },
                                {
                                    type: LinkTypes.LINKEDIN,
                                    link: "https://www.linkedin.com/in/andrewtong/",
                                },
                            ]}
                        />
                        <SectionTitle id="work-experience" title="Work Experience"/>
                        <WorkHistory/>
                        <SectionTitle id="projects" title="Projects"/>
                        <ProjectsGrid
                            projects={projects}
                            openDialogCallback={openProjectDialog}
                        />
                        <Dialog
                            open={isProjectDialogOpen}
                            onClose={closeProjectDialog}
                            maxWidth="lg"
                            fullWidth={true}
                        >
                            <ProjectPopover project={selectedProject}/>
                        </Dialog>
                        <Section
                            id="education"
                            title="Education"
                            contentRows={[
                                {
                                    title: "University of British Columbia",
                                    tagline: "Bachelor of Science, Computer Science",
                                    description: "Currently pursuing a degree in Computer Science at UBC. Formal education in Java, C, C++, object oriented design, development of test cases, algorithmic runtime, computer systems, and general code design principles.",
                                    imgSrc: ImageSrcs.educationLogos.ubcLogo,
                                    startDate: new Date(2017, 8),
                                    endDate: new Date(2022, 4),
                                },
                                {
                                    title: "R.A. McMath Secondary School",
                                    tagline: "Class Valedictorian, Top Student",
                                    description: "Graduated as class valedictorian, nominated by staff and students from a class of ~300 students. Recipient of many top student awards including top IT student, model leadership student, and top all-around student.",
                                    imgSrc: ImageSrcs.educationLogos.mcmathLogo,
                                    startDate: new Date(2012, 8),
                                    endDate: new Date(2017, 5),
                                },
                            ]}
                        />
                        <Section
                            id="awards"
                            title="Awards & Achievements"
                            contentRows={[
                                {
                                    title: "UBC Schulich Leader",
                                    description: "1 of 4 recipients of an $80,000 scholarship, one of the largest scholarships in the world, for contributions and passion in STEM (specifically, technology and coding).",
                                    startDate: new Date(2017, 3),
                                    endDate: new Date(2017, 3),
                                },
                                {
                                    title: "McMath Valedictorian",
                                    description: "Graduated as class valedictorian, nominated by staff and students from a class of ~300 students. Recipient of many top student awards including top IT student, model leadership student, and top all-around student.",
                                    startDate: new Date(2017, 5),
                                    endDate: new Date(2017, 5),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </body>
        </div>
    );
}

export default App;
