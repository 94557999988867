import {JobExperience} from "../interfaces";
import {ImageSrcs} from "../imageSrcs";

export const jobExperiences: JobExperience[] = [
    {
        title: "Software Engineer (FTE)",
        company: "Meta",
        description: "Built the Salus Investigation Tool for the Salus team from scratch, a tool that allows engineers to internally query Facebook objects by FBID to quickly see object metadata, expediting the investigation process for SEVs during oncall shifts. Also contributed to numerous products including Messenger, Meta Portal, Oculus Store, and Horizon Worlds. Unfortunately as a newhire, affected by mass layoffs of November 2022 where 13,000+ employees were let go.",
        logoSrc: ImageSrcs.companyLogos.metaLogo,
        startDate: new Date(2022, 8),
        endDate: new Date(2022, 10),
        cardTopBorderColour: "blue",
    },
    {
        title: "SDE Intern",
        company: "Amazon / PLCC Acquisitions Team",
        description: "Completely recreated the the Amazon store card application in React. Designed and built several reusable components used across several teams at Amazon.",
        logoSrc: ImageSrcs.companyLogos.amazonLogo,
        startDate: new Date(2021, 4),
        endDate: new Date(2021, 7),
        cardTopBorderColour: "orange",
    },
    {
        title: "Software Developer Intern",
        company: "SAP / Data Wrangling Team",
        description: "Worked in Javascript, HTML, and CSS/Less on SAP's software analytics cloud (SAC) product and data warehouse cloud (DWC). Assisted in investigating and expediting the process to provide wrangling as a service, addressed and fixed customer support bugs, wrote automated tests in Jasmine, conducted regression testing, and provided code reviews on Gerrit.",
        logoSrc: ImageSrcs.companyLogos.sapLogo,
        startDate: new Date(2019, 8),
        endDate: new Date(2020, 4),
        cardTopBorderColour: "CornflowerBlue",
    },
    {
        title: "Frontend Developer Intern",
        company: "TuGo / Digital Solutions Team",
        description: "Coded in Groovy to build and debug company projects, fulfilled bug and feature tickets through JIRA with version control in BitBucket, devised unit; integration; and functional tests in Spock and Grails, actively participated in the team's agile development process and sprint planning meetings.",
        logoSrc: ImageSrcs.companyLogos.tugoLogo,
        startDate: new Date(2019, 4),
        endDate: new Date(2019, 7),
        dotBackgroundColour: "white",
        cardTopBorderColour: "teal",
    },
];