import React from "react";
import { Grid } from '@material-ui/core';
import ProjectThumbnail from "../ProjectThumbnail";
import {Project} from "../../../../constants/interfaces";
import "./styles.scss";

export interface IProps {
    projects: Project[],
    openDialogCallback: (project: Project) => unknown,
}

export default function ProjectsGrid(props: IProps) {
    const { projects, openDialogCallback } = props;

    function generateProjectDivs(): React.ReactNode {
        const outputDivs: React.ReactNode[] = [];
        projects.forEach((project: Project) => {
            outputDivs.push(
                <Grid item xs={3}>
                    <ProjectThumbnail
                        projectTitle={project.title}
                        thumbnailImgSrc={project.thumbnail}
                        openProjectCallback={() => {openDialogCallback(project)}}
                    />
                </Grid>
            );
        });
        return <React.Fragment>{outputDivs}</React.Fragment>
    }

    return (
        <Grid container spacing={1} style={{maxWidth: "1300px"}}>
            {generateProjectDivs()}
        </Grid>
    );
}