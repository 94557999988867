import React from 'react';
import {ImageSrcs} from "../../../../constants/imageSrcs";
import {SidebarLink} from "../../../../constants/interfaces";
import "./styles.scss";

export interface IProps {
    links: SidebarLink[];
}

const SidebarLinks = (props: IProps) => {
    const { links } = props;

    function generateSidebarLinks() {
        const linkElements: React.ReactNode[] = [];

        links.forEach((link: SidebarLink) => {
            linkElements.push(
                <li className="sidebar-links__link">
                    <a href={`#${link.linkSrc}`}>{link.linkName}</a>
                </li>
            );
        });

        return linkElements;
    }

    return (<React.Fragment>{generateSidebarLinks()}</React.Fragment>);
}

export default function NavigationSidebar(props: IProps) {
    const { links } = props;

    return (
        <div className="sidebar-links__container">
            <div className="sidebar-links__content">
                <ul className="sidebar-links__links-list">
                    <a href="#main">
                        <img alt="Profile" className="sidebar-links__profileImg" src={ImageSrcs.defaults.profileImg}/>
                    </a>
                    <SidebarLinks links={links}/>
                </ul>
            </div>
        </div>
    );
}
