import liveSample from "./data/img/defaults/img_sample.png";
import youtube from "./data/img/defaults/img_youtube.png";
import amazonLogo from "./data/img/company_logos/amazon.png";
import metaLogo from "./data/img/company_logos/meta.png";
import sapLogo from "./data/img/company_logos/sap.png";
import tugoLogo from "./data/img/company_logos/tugo.png";
import academiaTimer__comparisonImg from "./data/img/project_imgs/academiaTimer/comparisonImg.png";
import academiaTimer__imagePages from "./data/img/project_imgs/academiaTimer/imagePages.png";
import academiaTimer__landingPage from "./data/img/project_imgs/academiaTimer/landingPage.jpg";
import academiaTimer__projectThumbnail from "./data/img/project_imgs/academiaTimer/projectThumbnail.jpg";
import arithmeticPracticeGame__projectThumbnail from "./data/img/project_imgs/arithmeticPracticeGame/projectThumbnail.jpg";
import arithmeticPracticeGame__wideScreenImg from "./data/img/project_imgs/arithmeticPracticeGame/widescreenImg.png";
import autoPodcastEditor__gui from "./data/img/project_imgs/autoPodcastEditor/gui.png";
import bloodMd__projectThumbnail from "./data/img/project_imgs/bloodMd/projectThumbnail.jpg";
import dnaFighter2000__projectThumbnail from "./data/img/project_imgs/dnaFighter2000/projectThumbnail.jpg";
import dodge__projectThumbnail from "./data/img/project_imgs/dodge/projectThumbnail.jpg";
import hackathonSimulator2021__codingSimulator from "./data/img/project_imgs/hackathonSimulator2021/codingSimulator.png";
import hackathonSimulator2021__lastMinuteSubmission from "./data/img/project_imgs/hackathonSimulator2021/lastMinuteSubmission.png";
import hackathonSimulator2021__projectThumbnail from "./data/img/project_imgs/hackathonSimulator2021/projectThumbnail.png";
import hackathonSimulator2021__swagLineup from "./data/img/project_imgs/hackathonSimulator2021/swagLineup.png";
import musicPopularityVisualization__projectThumbnail from "./data/img/project_imgs/musicPopularityVisualization/projectThumbnail.jpg";
import nfcSignInSystem__projectThumbnail from "./data/img/project_imgs/nfcSignInSystem/projectThumbnail.jpg";
import nightmodeMessenger__projectThumbnail from "./data/img/project_imgs/nightmodeMessenger/projectThumbnail.jpg";
import picross__gameScreenshot from "./data/img/project_imgs/picross/gameScreenshot.jpg";
import picross__projectThumbnail from "./data/img/project_imgs/picross/projectThumbnail.jpg";
import profStats__projectThumbnail from "./data/img/project_imgs/profStats/projectThumbnail.jpg";
import rma__projectThumbnail from "./data/img/project_imgs/rma/projectThumbnail.jpg";
import titrationSimulator__projectThumbnail from "./data/img/project_imgs/titrationSimulator/projectThumbnail.png";
import twitchClipGrabber__projectThumbnail from "./data/img/project_imgs/twitchClipGrabber/projectThumbnail.png";
import twitchClipGrabber__sampleClipScreenshot from "./data/img/project_imgs/twitchClipGrabber/sampleClipScreenshot.png";
import ubcWiki__projectThumbnail from "./data/img/project_imgs/ubcWiki/projectThumbnail.png";
import ubcWiki__wikiPageExample from "./data/img/project_imgs/ubcWiki/wikiPageExample.png";
import portfolioWebsiteReact__projectThumbnail from "./data/img/project_imgs/portfolioWebsiteReact/projectThumbnail.png";
import portfolioWebsiteReact__landingPage from "./data/img/project_imgs/portfolioWebsiteReact/landingPage.png";
import portfolioWebsiteReact__workExperienceSection from "./data/img/project_imgs/portfolioWebsiteReact/workExperienceSection.png";
import portfolioWebsiteReact__projectsSection from "./data/img/project_imgs/portfolioWebsiteReact/projectsSection.png";
import portfolioWebsiteReact__projectPopover from "./data/img/project_imgs/portfolioWebsiteReact/projectPopover.png";
import portfolioWebsiteReact__educationSection from "./data/img/project_imgs/portfolioWebsiteReact/educationSection.png";
import portfolioWebsiteReact__achievementsSection from "./data/img/project_imgs/portfolioWebsiteReact/achievementsSection.png";
import buildingGame__projectThumbnail from "./data/img/project_imgs/buildingGame/projectThumbnail.png";
import buildingGame__gameScreenshot from "./data/img/project_imgs/buildingGame/gameScreenshot.png";
import chroniclr__projectThumbnail from "./data/img/project_imgs/chroniclr/projectThumbnail.png";
import chroniclr__journalTab from "./data/img/project_imgs/chroniclr/journalTab.png";
import chroniclr__questsTab from "./data/img/project_imgs/chroniclr/questsTab.png";
import musicDsl__projectThumbnail from "./data/img/project_imgs/musicDsl/projectThumbnail.png";
import musicDsl__programScreenshot from "./data/img/project_imgs/musicDsl/programScreenshot.png";
import musicDsl__grammarRules from "./data/img/project_imgs/musicDsl/grammarRules.png";
import bridges__projectThumbnail from "./data/img/project_imgs/bridges/projectThumbnail.png";
import bridges__landingPage from "./data/img/project_imgs/bridges/landingPage.png";
import bridges__createPosting1 from "./data/img/project_imgs/bridges/createPosting1.png";
import bridges__createPosting2 from "./data/img/project_imgs/bridges/createPosting2.png";
import bridges__createPostingConfirmation from "./data/img/project_imgs/bridges/createPostingConfirmation.png";
import bridges__assistPosting1 from "./data/img/project_imgs/bridges/assistPosting1.png";
import bridges__assistPosting2 from "./data/img/project_imgs/bridges/assistPosting2.png";
import bridges__postingBrowser1 from "./data/img/project_imgs/bridges/postingBrowser1.png";
import bridges__postingBrowser2 from "./data/img/project_imgs/bridges/postingBrowser2.png";


import profileImg from "./data/img/defaults/profile.jpg";
import mcmathLogo from "./data/img/education/mcmath.png";
import ubcLogo from "./data/img/education/ubc.png";

export const ImageSrcs = {
    defaults: {
        profileImg,
        liveSample,
        youtube,
    },
    companyLogos: {
        amazonLogo,
        metaLogo,
        sapLogo,
        tugoLogo,
    },
    projectImgs: {
        academiaTimer: {
            academiaTimer__comparisonImg,
            academiaTimer__imagePages,
            academiaTimer__landingPage,
            academiaTimer__projectThumbnail,
        },
        arithmeticPracticeGame: {
            arithmeticPracticeGame__projectThumbnail,
            arithmeticPracticeGame__wideScreenImg,
        },
        autoPodcastEditor: {
            autoPodcastEditor__gui,
        },
        bloodMd: {
            bloodMd__projectThumbnail,
        },
        dnaFighter2000: {
            dnaFighter2000__projectThumbnail,
        },
        dodge: {
            dodge__projectThumbnail,
        },
        hackathonSimulator2021: {
            hackathonSimulator2021__codingSimulator,
            hackathonSimulator2021__lastMinuteSubmission,
            hackathonSimulator2021__projectThumbnail,
            hackathonSimulator2021__swagLineup,
        },
        musicPopularityVisualization: {
            musicPopularityVisualization__projectThumbnail,
        },
        nightmodeMessenger: {
            nightmodeMessenger__projectThumbnail,
        },
        nfcSignInSystem: {
            nfcSignInSystem__projectThumbnail,
        },
        picross: {
            picross__gameScreenshot,
            picross__projectThumbnail,
        },
        profStats: {
            profStats__projectThumbnail,
        },
        rma: {
            rma__projectThumbnail,
        },
        titrationSimulator: {
            titrationSimulator__projectThumbnail,
        },
        twitchClipGrabber: {
            twitchClipGrabber__sampleClipScreenshot,
            twitchClipGrabber__projectThumbnail,
        },
        ubcWiki: {
            ubcWiki__projectThumbnail,
            ubcWiki__wikiPageExample,
        },
        portfolioWebsiteReact: {
            portfolioWebsiteReact__projectThumbnail,
            portfolioWebsiteReact__landingPage,
            portfolioWebsiteReact__workExperienceSection,
            portfolioWebsiteReact__projectsSection,
            portfolioWebsiteReact__projectPopover,
            portfolioWebsiteReact__educationSection,
            portfolioWebsiteReact__achievementsSection,
        },
        buildingGame: {
            buildingGame__projectThumbnail,
            buildingGame__gameScreenshot,
        },
        chroniclr: {
            chroniclr__projectThumbnail,
            chroniclr__journalTab,
            chroniclr__questsTab,
        },
        bridges: {
            bridges__projectThumbnail,
            bridges__landingPage,
            bridges__createPosting1,
            bridges__createPosting2,
            bridges__createPostingConfirmation,
            bridges__assistPosting1,
            bridges__assistPosting2,
            bridges__postingBrowser1,
            bridges__postingBrowser2,
        },
        musicDsl: {
            musicDsl__projectThumbnail,
            musicDsl__programScreenshot,
            musicDsl__grammarRules,
        },
    },
    educationLogos: {
        mcmathLogo,
        ubcLogo,
    },
}