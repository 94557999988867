import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import "./styles.scss";
import {JobExperience} from "../../../../constants/interfaces";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import {MonthNames} from "../../../../constants/enums/dates";
import {makeStyles} from "@material-ui/core/styles";

export interface IProps {
    jobExperience: JobExperience;
    horizontalOffset: string;
    isLastItem?: boolean;
}

export default function CompanyCard(props: IProps) {
    const { jobExperience, horizontalOffset, isLastItem } = props;

    const useStyles = makeStyles({root:{background: jobExperience.dotBackgroundColour}});
    const classes = useStyles();
    const cardBorder = {
        borderTopColor: jobExperience.cardTopBorderColour,
        borderStyle: "solid",
        borderWidth: "12px 0 0 0",
    }

    function generateDateRangeString(startDate: Date, endDate: Date) {
        const startMonth: string = MonthNames[startDate.getMonth()];
        const startYear: string = startDate.getFullYear().toString();
        const endMonth: string = MonthNames[endDate.getMonth()];
        const endYear: string = endDate.getFullYear().toString();

        return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
    }

    return (
        <div className="companyCard">
            <TimelineItem style={{right: horizontalOffset}}>
                <TimelineOppositeContent>
                    <p className="job__date">
                        {generateDateRangeString(jobExperience.startDate, jobExperience.endDate)}
                    </p>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot classes={{root: classes.root}}><span className="icon"><img src={jobExperience.logoSrc} alt={jobExperience.company}/></span></TimelineDot>
                    {!isLastItem && <TimelineConnector/>}
                </TimelineSeparator>
                <TimelineContent>
                    <Card style={cardBorder}>
                        <CardContent className="card-content">
                            <h3 className="job__title">{jobExperience.title}</h3>
                            <h3 className="job__company">{jobExperience.company}</h3>
                            <p className="job__description">{jobExperience.description}</p>
                        </CardContent>
                    </Card>
                </TimelineContent>
            </TimelineItem>
        </div>
    );
}
