import {Technology} from "../interfaces";
import {TechnologyType} from "./technologyTypes";

const Python: Technology = {
    name: "Python",
    type: TechnologyType.Language,
    iconDefinition: ["fab", "python"],
}

const Java: Technology = {
    name: "Java",
    type: TechnologyType.Language,
    iconDefinition: ["fab", "java"],
}

const JavaScript: Technology = {
    name: "JavaScript",
    type: TechnologyType.Language,
    iconDefinition: ["fab", "js"],
}

const CSharp: Technology = {
    name: "C#",
    type: TechnologyType.Language,
    iconDefinition: ["fab", "slack-hash"],
}

const ReactJS: Technology = {
    name: "ReactJS",
    type: TechnologyType.Framework,
    iconDefinition: ["fab", "react"],
}

const D3: Technology = {
    name: "D3",
    type: TechnologyType.Framework,
    iconDefinition: ["fas", "chart-bar"],
}

const GMStudio: Technology = {
    name: "GM: Studio",
    type: TechnologyType.Engine,
    iconDefinition: ["fas", "gamepad"],
}

const Unity: Technology = {
    name: "Unity",
    type: TechnologyType.Engine,
    iconDefinition: ["fab", "unity"],
}

const ChromeExtension: Technology = {
    name: "Chrome Extension",
    type: TechnologyType.Engine,
    iconDefinition: ["fab", "chrome"],
}

const SQL: Technology = {
    name: "SQL",
    type: TechnologyType.Language,
    iconDefinition: ["fas", "database"],
}

const NFC: Technology = {
    name: "NFC",
    type: TechnologyType.Framework,
    iconDefinition: ["fas", "signal"], // TODO: Fix this
}

const Flask: Technology = {
    name: "Flask",
    type: TechnologyType.Framework,
    iconDefinition: ["fas", "flask"],
}

const Antlr: Technology = {
    name: "ANTLR",
    type: TechnologyType.Language,
    iconDefinition: ["fas", "a"]
}

const MongoDB: Technology = {
    name: "MongoDB",
    type: TechnologyType.Framework,
    iconDefinition: ["fas", "leaf"]
}

const Express: Technology = {
    name: "Express",
    type: TechnologyType.Framework,
    iconDefinition: ["fas", "e"]
}

export const Technologies = {
    Python,
    Java,
    JavaScript,
    CSharp,
    ReactJS,
    D3,
    GMStudio,
    Unity,
    ChromeExtension,
    SQL,
    NFC,
    Flask,
    Antlr,
    MongoDB,
    Express,
}