import { Technologies } from "../enums/technologies";
import { Project } from "../interfaces";
import {LinkTypes} from "../enums/linkTypes";
import {GalleryItemTypes, YouTubeEmbed} from "../enums/galleryItemTypes";
import {redditModerationAssistant_descriptionContent} from "./project_child_content/redditModerationAssistant"
import {academiaTimer_descriptionContent} from "./project_child_content/academiaTimer";
import {nfcCodingProject_descriptionContent} from "./project_child_content/nfcCodingProject";
import {autoPodcastEditor_descriptionContent} from "./project_child_content/autoPodcastEditor";
import {picross_descriptionContent} from "./project_child_content/picross";
import {profStats_descriptionContent} from "./project_child_content/profStats";
import {dnaFighter2000_descriptionContent} from "./project_child_content/dnaFighter2000";
import {bloodMd_descriptionContent} from "./project_child_content/bloodMd";
import {arithmeticPracticeGame_descriptionContent} from "./project_child_content/arithmeticPracticeGame";
import {titrationSimulator_descriptionContent} from "./project_child_content/titrationSimulator";
import {musicPopularityVisualization_descriptionContent} from "./project_child_content/musicPopularityVisualization";
import {hackathonSimulator2021_descriptionContent} from "./project_child_content/hackathonSimulator2021";
import {ImageSrcs} from "../imageSrcs";
import {portfolioWebsiteReact__descriptionContent} from "./project_child_content/portfolioWebsiteReact";
import {buildingGame__descriptionContent} from "./project_child_content/buildingGame";
import {chroniclr__descriptionContent} from "./project_child_content/chroniclr";
import {musicDsl__descriptionContent} from "./project_child_content/musicDsl";
import { bridges__descriptionContent } from "./project_child_content/bridges";

export const emptyProject: Project = {
    title: "Template Project",
    thumbnail: "#",
    date: new Date(0, 0),
    technologies: [],
    galleryItems: [],
};

export const projects: Project[] = [
    {
        title: "Reddit Moderation Assistant",
        thumbnail: ImageSrcs.projectImgs.rma.rma__projectThumbnail,
        date: new Date(2020, 5),
        technologies: [Technologies.Python],
        description: redditModerationAssistant_descriptionContent(),
        galleryItems: [
            {
                content: "https://andrewtong.me/RMA-Website/",
                thumbnail: ImageSrcs.projectImgs.rma.rma__projectThumbnail,
                description: "RMA Project Feature Website",
                type: GalleryItemTypes.IFRAME,
                width: 800,
                height: 600,
            }
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/RMA",
            },
            {
                type: LinkTypes.CUSTOM,
                link: "https://andrewtong.me/RMA-Website/",
                customLinkText: "Project Showcase Site"
            }
        ],
    },
    {
        title: "NFC Sign-In System",
        thumbnail: ImageSrcs.projectImgs.nfcSignInSystem.nfcSignInSystem__projectThumbnail,
        date: new Date(2019, 6),
        technologies: [Technologies.Python, Technologies.SQL, Technologies.NFC, Technologies.Flask],
        description: nfcCodingProject_descriptionContent(),
        galleryItems: [
            {
                content: YouTubeEmbed("A6Jl4ZHgw3o"),
                thumbnail: ImageSrcs.defaults.youtube,
                description: "NFC Sign-In System Video",
                type: GalleryItemTypes.HTMLCONTENT,
            }
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/NFCSigninSystem",
            }
        ],
    },
    {
        title: "AutoPodcastEditor",
        thumbnail: ImageSrcs.projectImgs.autoPodcastEditor.autoPodcastEditor__gui,
        date: new Date(2019, 3),
        technologies: [Technologies.Python],
        description: autoPodcastEditor_descriptionContent(),
        galleryItems: [
            {
                content: YouTubeEmbed("kMJ4Bx4BBBo"),
                thumbnail: ImageSrcs.defaults.youtube,
                description: "NFC Sign-In System Video",
                type: GalleryItemTypes.HTMLCONTENT,
            },
            {
                content: ImageSrcs.projectImgs.autoPodcastEditor.autoPodcastEditor__gui,
                thumbnail: ImageSrcs.projectImgs.autoPodcastEditor.autoPodcastEditor__gui,
                description: "GUI Screenshot",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/autoPodcastEditor",
            }
        ],
    },
    {
        title: "Picross",
        thumbnail: ImageSrcs.projectImgs.picross.picross__projectThumbnail,
        date: new Date(2019, 4),
        technologies: [Technologies.JavaScript, Technologies.GMStudio],
        description: picross_descriptionContent(),
        galleryItems: [
            {
                content: "https://andrewtong.me/PicrossWebApp/",
                thumbnail: "",
                description: "Try out the application above!",
                type: GalleryItemTypes.IFRAME,
                width: 1030,
                height: 775,
                iframeZoomOut: true
            },
            {
                content: YouTubeEmbed("Fwusal6Msfs"),
                thumbnail: ImageSrcs.defaults.youtube,
                description: "Picross Showcase Video",
                type: GalleryItemTypes.HTMLCONTENT,
            },
            {
                content: ImageSrcs.projectImgs.picross.picross__gameScreenshot,
                thumbnail: ImageSrcs.projectImgs.picross.picross__gameScreenshot,
                description: "Game Screenshot",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/picross",
            }
        ],
    },
    {
        title: "Academia Timer",
        thumbnail: ImageSrcs.projectImgs.academiaTimer.academiaTimer__projectThumbnail,
        date: new Date(2019, 6),
        technologies: [Technologies.JavaScript, Technologies.GMStudio],
        description: academiaTimer_descriptionContent(),
        galleryItems: [
            {
                content: "https://andrewtong.me/academiaTimerWebApp/",
                thumbnail: "",
                description: "Try out the application above!",
                type: GalleryItemTypes.IFRAME,
                width: 705,
                height: 405,
            },
            {
                content: ImageSrcs.projectImgs.academiaTimer.academiaTimer__landingPage,
                thumbnail: ImageSrcs.projectImgs.academiaTimer.academiaTimer__landingPage,
                description: "Application Landing Page Screenshot",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.academiaTimer.academiaTimer__comparisonImg,
                thumbnail: ImageSrcs.projectImgs.academiaTimer.academiaTimer__comparisonImg,
                description: "Comparison of Timer from Show to Creation",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.academiaTimer.academiaTimer__imagePages,
                thumbnail: ImageSrcs.projectImgs.academiaTimer.academiaTimer__imagePages,
                description: "Application Page Screenshots",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/academiaTimer",
            }
        ],
    },
    {
        title: "ProfStats",
        thumbnail: ImageSrcs.projectImgs.profStats.profStats__projectThumbnail,
        date: new Date(2019, 0),
        technologies: [Technologies.Python],
        description: profStats_descriptionContent(),
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.profStats.profStats__projectThumbnail,
                thumbnail: ImageSrcs.projectImgs.profStats.profStats__projectThumbnail,
                description: "ProfStats Promotional Image",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/nwHacks2019-ProfStats",
            }
        ],
    },
    {
        title: "UBC Wiki",
        thumbnail: ImageSrcs.projectImgs.ubcWiki.ubcWiki__projectThumbnail,
        date: new Date(2019, 7),
        technologies: [Technologies.JavaScript, Technologies.ReactJS],
        description: "Proof of concept ReactJS site to show the potential of a UBC wiki page. This page was made with the intent of informing students what courses are about, what resources or past exams they can use, and the course and professor ratings. All of the information is stored on a static API endpoint hosted by stdlib, where we store the information in JSON files that are queried with AJAX calls.",
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.ubcWiki.ubcWiki__projectThumbnail,
                thumbnail: ImageSrcs.projectImgs.ubcWiki.ubcWiki__projectThumbnail,
                description: "Wiki Search Page",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.ubcWiki.ubcWiki__wikiPageExample,
                thumbnail: ImageSrcs.projectImgs.ubcWiki.ubcWiki__wikiPageExample,
                description: "Wiki Course Page",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/ubcwiki",
            }
        ],
    },
    {
        title: "DNA Fighter 2000",
        thumbnail: ImageSrcs.projectImgs.dnaFighter2000.dnaFighter2000__projectThumbnail,
        date: new Date(2018, 9),
        technologies: [Technologies.JavaScript, Technologies.GMStudio],
        description: dnaFighter2000_descriptionContent(),
        galleryItems: [
            {
                content: "https://andrewtong.me/DNAFighter2000WebApp/",
                thumbnail: "",
                description: "Try out the application above!",
                type: GalleryItemTypes.IFRAME,
                width: 1050,
                height: 780,
                iframeZoomOut: true,
            },
            {
                content: YouTubeEmbed("Qm4QYxmhZPo"),
                thumbnail: ImageSrcs.defaults.youtube,
                description: "DNA Fighter 2000 Showcase Video",
                type: GalleryItemTypes.HTMLCONTENT,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/picross",
            }
        ],
    },
    {
        title: "Dodge",
        thumbnail: ImageSrcs.projectImgs.dodge.dodge__projectThumbnail,
        date: new Date(2019, 0),
        technologies: [Technologies.JavaScript, Technologies.GMStudio],
        description: "Dodge was the product of a mini game-jam that I participated in, made in under 24 hours. It's a simple mobile game that can be played on Android phones by downloading the .apk file. The objective of the game is to dodge oncoming walls by moving your character into the gaps. As the game progresses, the walls move faster and appear more frequently.",
        galleryItems: [
            {
                content: "https://andrewtong.me/DodgeWebApp/",
                thumbnail: "",
                description: "Try out the application above!",
                type: GalleryItemTypes.IFRAME,
                width: 400,
                height: 804,
                iframeZoomOut: true,
            },
            {
                content: YouTubeEmbed("__yfW2rXVnI"),
                thumbnail: ImageSrcs.defaults.youtube,
                description: "DNA Fighter 2000 Showcase Video",
                type: GalleryItemTypes.HTMLCONTENT,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/dodge",
            }
        ],
    },
    {
        title: "BloodMD",
        thumbnail: ImageSrcs.projectImgs.bloodMd.bloodMd__projectThumbnail,
        date: new Date(2019, 1),
        technologies: [Technologies.Python],
        description: bloodMd_descriptionContent(),
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.bloodMd.bloodMd__projectThumbnail,
                thumbnail: ImageSrcs.projectImgs.bloodMd.bloodMd__projectThumbnail,
                description: "",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/XdHacks2019",
            }
        ],
    },
    {
        title: "Arithmetic Practice Game",
        thumbnail: ImageSrcs.projectImgs.arithmeticPracticeGame.arithmeticPracticeGame__projectThumbnail,
        date: new Date(2019, 2),
        technologies: [Technologies.Java],
        description: arithmeticPracticeGame_descriptionContent(),
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.arithmeticPracticeGame.arithmeticPracticeGame__wideScreenImg,
                thumbnail: ImageSrcs.projectImgs.arithmeticPracticeGame.arithmeticPracticeGame__wideScreenImg,
                description: "",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/cpsc210_arithmeticGame",
            }
        ],
    },
    {
        title: "Titration Simulator",
        thumbnail: ImageSrcs.projectImgs.titrationSimulator.titrationSimulator__projectThumbnail,
        date: new Date(2019, 8),
        technologies: [Technologies.JavaScript, Technologies.GMStudio],
        description: titrationSimulator_descriptionContent(),
        galleryItems: [
            {
                content: "https://andrewtong.me/TitrationSimulatorWebApp/",
                thumbnail: "",
                description: "Try out the application above!",
                type: GalleryItemTypes.IFRAME,
                width: 400,
                height: 804,
                iframeZoomOut: true,
            },
            {
                content: ImageSrcs.projectImgs.titrationSimulator.titrationSimulator__projectThumbnail,
                thumbnail: ImageSrcs.projectImgs.titrationSimulator.titrationSimulator__projectThumbnail,
                description: "",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/cpsc210_arithmeticGame",
            }
        ],
    },
    {
        title: "Music Popularity Visualization",
        thumbnail: ImageSrcs.projectImgs.musicPopularityVisualization.musicPopularityVisualization__projectThumbnail,
        date: new Date(2019, 8),
        technologies: [Technologies.JavaScript, Technologies.D3],
        description: musicPopularityVisualization_descriptionContent(),
        galleryItems: [
            {
                content: "https://andrewtong.me/MusicPopularityVisualization/",
                thumbnail: "",
                description: "Interact with the data visualization above!",
                type: GalleryItemTypes.IFRAME,
                width: 1100,
                height: 800,
                iframeZoomOut: true,
            },
            {
                content: ImageSrcs.projectImgs.musicPopularityVisualization.musicPopularityVisualization__projectThumbnail,
                thumbnail: ImageSrcs.projectImgs.musicPopularityVisualization.musicPopularityVisualization__projectThumbnail,
                description: "Data Visualization Screenshot",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/MusicPopularityVisualization",
            },
            {
                type: LinkTypes.CUSTOM,
                link: "https://andrewtong.me/MusicPopularityVisualization/",
                customLinkText: "View Visualization Externally"
            }
        ],
    },
    {
        title: "Nightmode Messenger",
        thumbnail: ImageSrcs.projectImgs.nightmodeMessenger.nightmodeMessenger__projectThumbnail,
        date: new Date(2019, 8),
        technologies: [Technologies.JavaScript, Technologies.ChromeExtension],
        description: "A simple and lightweight Chrome extension enabling night mode functionality on Facebook Messenger. Created to fill the necessity for a dark mode for Facebook's Messenger application on messenger.com",
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.nightmodeMessenger.nightmodeMessenger__projectThumbnail,
                thumbnail: ImageSrcs.projectImgs.nightmodeMessenger.nightmodeMessenger__projectThumbnail,
                description: "Chrome Extension Screenshot",
                type: GalleryItemTypes.IMAGE,
            }
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/nightmodeMessenger",
            },
            {
                type: LinkTypes.CUSTOM,
                customLinkText: "Chrome Web Store Extension Link",
                link: "https://chrome.google.com/webstore/detail/nightmode-messenger/idblamhdnimnenghjcnidjijkjclfhjf"
            }
        ],
    },
    {
        title: "Twitch Clip Grabber",
        thumbnail: ImageSrcs.projectImgs.twitchClipGrabber.twitchClipGrabber__projectThumbnail,
        date: new Date(2021, 3),
        technologies: [Technologies.JavaScript, Technologies.ChromeExtension],
        description: "A simple chrome extension that makes it easy to quickly download Twitch clips. Given that Twitch does not provide an easy way to download clips, this extension provides a popup that can be opened by clicking the extension icon while on a clip page to easily download the clip.",
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.twitchClipGrabber.twitchClipGrabber__sampleClipScreenshot,
                thumbnail: ImageSrcs.projectImgs.twitchClipGrabber.twitchClipGrabber__sampleClipScreenshot,
                description: "Chrome Extension Screenshot",
                type: GalleryItemTypes.IMAGE,
            }
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/twitchClipGrabber",
            },
        ],
    },
    {
        title: "Hackathon Simulator 2021",
        thumbnail: ImageSrcs.projectImgs.hackathonSimulator2021.hackathonSimulator2021__projectThumbnail,
        date: new Date(2021, 0),
        technologies: [Technologies.CSharp, Technologies.Unity],
        description: hackathonSimulator2021_descriptionContent(),
        galleryItems: [
            {
                content: "https://andrewtong.me/HackathonSimulator2021_WebApp/",
                thumbnail: "",
                description: "Try out the game above! Should I even call this a game?",
                type: GalleryItemTypes.IFRAME,
                width: 1030,
                height: 775,
                iframeZoomOut: true
            },
            {
                content: ImageSrcs.projectImgs.hackathonSimulator2021.hackathonSimulator2021__swagLineup,
                thumbnail: ImageSrcs.projectImgs.hackathonSimulator2021.hackathonSimulator2021__swagLineup,
                description: "Swag Lineup Minigame",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.hackathonSimulator2021.hackathonSimulator2021__codingSimulator,
                thumbnail: ImageSrcs.projectImgs.hackathonSimulator2021.hackathonSimulator2021__codingSimulator,
                description: "Coding Simulator",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.hackathonSimulator2021.hackathonSimulator2021__lastMinuteSubmission,
                thumbnail: ImageSrcs.projectImgs.hackathonSimulator2021.hackathonSimulator2021__lastMinuteSubmission,
                description: "Last Minute Project Submission",
                type: GalleryItemTypes.IMAGE,
            }
        ],
        links: [
            {
                type: LinkTypes.CUSTOM,
                customLinkText: "Open Game in New Tab",
                link: "https://andrewtong.me/HackathonSimulator2021_WebApp/",
            },
        ],
    },
    {
        title: "Portfolio Website (React)",
        thumbnail: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__projectThumbnail,
        date: new Date(2021, 8),
        technologies: [Technologies.JavaScript, Technologies.ReactJS],
        description: portfolioWebsiteReact__descriptionContent(),
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__landingPage,
                thumbnail: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__landingPage,
                description: "Portfolio Landing Page",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__workExperienceSection,
                thumbnail: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__workExperienceSection,
                description: "Work Experience Section",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__projectsSection,
                thumbnail: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__projectsSection,
                description: "Projects Section",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__projectPopover,
                thumbnail: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__projectPopover,
                description: "Custom Project Popover",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__educationSection,
                thumbnail: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__educationSection,
                description: "Education Section",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__achievementsSection,
                thumbnail: ImageSrcs.projectImgs.portfolioWebsiteReact.portfolioWebsiteReact__achievementsSection,
                description: "Achievements Section",
                type: GalleryItemTypes.IMAGE,
            },
        ],
    },
    {
        title: "Building Game Plugin",
        thumbnail: ImageSrcs.projectImgs.buildingGame.buildingGame__projectThumbnail,
        date: new Date(2021, 3),
        technologies: [Technologies.Java],
        description: buildingGame__descriptionContent(),
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.buildingGame.buildingGame__gameScreenshot,
                thumbnail: ImageSrcs.projectImgs.buildingGame.buildingGame__gameScreenshot,
                description: "Building Game Screenshot",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/BuildingGame",
            },
        ],
    },
    {
        title: "Chroniclr",
        thumbnail: ImageSrcs.projectImgs.chroniclr.chroniclr__projectThumbnail,
        date: new Date(2020, 0),
        technologies: [Technologies.JavaScript, Technologies.Python, Technologies.ReactJS],
        description: chroniclr__descriptionContent(),
        galleryItems: [
            {
                content: ImageSrcs.projectImgs.chroniclr.chroniclr__journalTab,
                thumbnail: ImageSrcs.projectImgs.chroniclr.chroniclr__journalTab,
                description: "Journal Tab",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.chroniclr.chroniclr__questsTab,
                thumbnail: ImageSrcs.projectImgs.chroniclr.chroniclr__questsTab,
                description: "Quests Tab",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/nwhacks2020-chroniclr",
            },
        ],
    },
    {
        title: "Bridges",
        thumbnail: ImageSrcs.projectImgs.bridges.bridges__projectThumbnail,
        date: new Date(2020, 4),
        technologies: [Technologies.JavaScript, Technologies.ReactJS, Technologies.Express, Technologies.MongoDB],
        description: bridges__descriptionContent(),
        galleryItems: [
            {
                content: YouTubeEmbed("rM3biT_x4uc"),
                thumbnail: ImageSrcs.defaults.youtube,
                description: "Bridges Demo Video",
                type: GalleryItemTypes.HTMLCONTENT,
            },
            {
                content: ImageSrcs.projectImgs.bridges.bridges__landingPage,
                thumbnail: ImageSrcs.projectImgs.bridges.bridges__landingPage,
                description: "Landing Page - Splits user into one of two workflows.",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.bridges.bridges__createPosting1,
                thumbnail: ImageSrcs.projectImgs.bridges.bridges__createPosting1,
                description: "Workflow 1 (Request for Help) - Post creation form (initial details).",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.bridges.bridges__createPosting2,
                thumbnail: ImageSrcs.projectImgs.bridges.bridges__createPosting2,
                description: "Workflow 1 (Request for Help) - Post creation form (additional details).",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.bridges.bridges__createPostingConfirmation,
                thumbnail: ImageSrcs.projectImgs.bridges.bridges__createPostingConfirmation,
                description: "Workflow 1 (Request for Help) - Confirmation of post details.",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.bridges.bridges__assistPosting1,
                thumbnail: ImageSrcs.projectImgs.bridges.bridges__assistPosting1,
                description: "Workflow 2 (Providing Assistance) - Selecting which category user can help with.",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.bridges.bridges__assistPosting2,
                thumbnail: ImageSrcs.projectImgs.bridges.bridges__assistPosting2,
                description: "Workflow 2 (Providing Assistance) - Details on how the user can help based on their selection.",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.bridges.bridges__postingBrowser1,
                thumbnail: ImageSrcs.projectImgs.bridges.bridges__postingBrowser1,
                description: "Workflow 2 (Providing Assistance) - Viewing requests for help.",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.bridges.bridges__postingBrowser2,
                thumbnail: ImageSrcs.projectImgs.bridges.bridges__postingBrowser2,
                description: "Workflow 2 (Providing Assistance) - Accepting a request for help.",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/Bridges",
            },
        ],
    },
    {
        title: "Music DSL",
        thumbnail: ImageSrcs.projectImgs.musicDsl.musicDsl__projectThumbnail,
        date: new Date(2021, 10),
        technologies: [Technologies.JavaScript, Technologies.ReactJS, Technologies.Antlr],
        description: musicDsl__descriptionContent(),
        galleryItems: [
            {
                content: "https://www.andrewtong.me/musicDSL/",
                thumbnail: "",
                description: "Try out the application above!",
                type: GalleryItemTypes.IFRAME,
                width: 800,
                height: 500,
                iframeZoomOut: false
            },
            {
                content: ImageSrcs.projectImgs.musicDsl.musicDsl__programScreenshot,
                thumbnail: ImageSrcs.projectImgs.musicDsl.musicDsl__programScreenshot,
                description: "Program Screenshot",
                type: GalleryItemTypes.IMAGE,
            },
            {
                content: ImageSrcs.projectImgs.musicDsl.musicDsl__grammarRules,
                thumbnail: ImageSrcs.projectImgs.musicDsl.musicDsl__grammarRules,
                description: "DSL Grammar Rules",
                type: GalleryItemTypes.IMAGE,
            },
        ],
        links: [
            {
                type: LinkTypes.GITHUB,
                link: "https://github.com/andrewtong0/musicDSL",
            },
            {
                type: LinkTypes.CUSTOM,
                link: "https://www.andrewtong.me/musicDSL/",
                customLinkText: "Project Demo Site"
            }
        ],
    },
];