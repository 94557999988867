import React from 'react';
import {Link} from "../../../../constants/interfaces";
import LandingPageLinkChip from "../LandingPageLinkChip";
import "./styles.scss";

export interface LandingPageLinksIProps {
    links: Link[] | undefined;
}

export interface IProps {
    id: string;
    firstName: string;
    lastName: string;
    programName: string;
    startDate: Date;
    endDate: Date;
    blurb: string;
    links?: Link[];
}

const LandingPageLinks = (props: LandingPageLinksIProps) => {
    const { links } = props;
    const linkElements: React.ReactNode[] = [];
    if (!!links) { links.forEach((link) => {linkElements.push(<LandingPageLinkChip link={link}/>)}); }
    return <div className="landingPage__linksContainer">
        {linkElements}
    </div>
}

export default function LandingPage(props: IProps) {
    const { id, firstName, lastName, programName, startDate, endDate, blurb, links } = props;

    function getProgramDurationString(startDate: Date, endDate: Date) {
        const currDate: Date = new Date();
        const startDateYearString: string = startDate.getFullYear().toString();
        const endDateYear: number = endDate.getFullYear();
        const endDateYearString: string = endDateYear <= currDate.getFullYear() ? endDateYear.toString() : `Present, Expected ${endDateYear}`;

        return (`(${startDateYearString} - ${endDateYearString})`);
    }

    return (
        <div id={id} className="landingPage landingPage__container">
            <div className="landingPage__contents">
                <div className="landingPage__name flex">
                    <h1 className="landingPage__firstName flex-initial">{firstName}</h1>
                    <h1 className="landingPage__lastName flex-initial">{lastName}</h1>
                </div>
                <div className="landingPage__programDetails flex">
                    <h1 className="landingPage__programName flex-initial">{programName}</h1>
                    <h1 className="landingPage__programDuration flex-initial">{getProgramDurationString(startDate, endDate)}</h1>
                </div>
                <div className="landingPage__blurb">{blurb}</div>
                <LandingPageLinks links={links}/>
            </div>
        </div>
    );
}
