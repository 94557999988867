import React from "react";
import {Box, Chip, Grid} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Technology, Link, Project} from "../../../../constants/interfaces";
import ProjectGallery from "../ProjectGallery";
import { MonthNames } from "../../../../constants/enums/dates";
import "./styles.scss";

export interface TechnologyChipProps {
    technologies: Technology[] | undefined;
}

export interface LinkChipProps {
    links: Link[] | undefined;
}

export interface IProps {
    project: Project;
}

const TechnologyChips = (props: TechnologyChipProps) => {
    const { technologies } = props;
    const chips: React.ReactNode[] = [];

    if (technologies && technologies.length !== 0) {
        technologies.forEach((technology: Technology) => {
            chips.push(<Chip
                className="mr-2"
                avatar={<FontAwesomeIcon icon={technology.iconDefinition}/>}
                label={technology.name}
                color={technology.type}
            />);
        });
    }

    return (
        <div>{chips}</div>
    );
};

const LinkChips = (props: LinkChipProps) => {
    const { links } = props;
    const chips: React.ReactNode[] = [];

    if (links && links.length !== 0) {
        links.forEach((link: Link) => {
            chips.push(
                <a href={link.link} target="_blank" rel="noopener noreferrer">
                    <Chip
                        className="link-chip mr-2 filter hover:brightness-75"
                        avatar={<FontAwesomeIcon icon={link.type.iconDefinition}/>}
                        label={link.customLinkText ?? link.type.name}
                    />
                </a>
            );
        });
    }

    return (
        <div>{chips}</div>
    );
}

export default function ProjectPopover(props: IProps) {
    const { project } = props;

    return (
        <Box className="font-sans popover-container text-white">
            <Grid className="header" container>
                <Grid item xs={9}>
                    <div className="header-text p-6">
                        <h1 className="text-5xl text-base py-2">{project.title}</h1>
                        <div className="subheader flex pt-2">
                            <Chip className="text-base mr-2" label={MonthNames[project.date.getMonth()] + " " + project.date.getFullYear().toString()}/>
                            <h3 className="text-base"><TechnologyChips technologies={project.technologies}/></h3>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className="flex justify-center">
                <ProjectGallery project={project}/>
            </div>
            <div className="project-details">
                <LinkChips links={project.links}/>
                <h3 className="text-base pt-2">{project.description}</h3>
            </div>
        </Box>
    );
}