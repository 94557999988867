import React, {useState} from "react";
import "./styles.scss";

export interface IProps {
    projectTitle: string;
    thumbnailImgSrc: string;
    openProjectCallback: () => unknown;
}

export default function ProjectThumbnail(props: IProps) {
    const { projectTitle, thumbnailImgSrc, openProjectCallback } = props;
    const [ showTitle, setShowTitle ] = useState(false);
    const [ isHovered, setIsHovered ] = useState(false);

    function mouseHover() { setIsHovered(true); }
    function mouseUnhover() { setIsHovered(false); }

    return (
        <div className="flex items-center justify-center" onClick={() => { openProjectCallback() }} onMouseEnter={mouseHover} onMouseLeave={mouseUnhover}>
            <figure
                className="relative max-w-xs cursor-pointer thumbnail-shadow"
                onMouseEnter={() => {setShowTitle(true);}}
                onMouseLeave={() => {setShowTitle(false);}}
            >
                <img className={`rounded-lg filter brightness-100 ${isHovered ? "thumbnail-hover" : ""}`} alt={projectTitle} src={thumbnailImgSrc}/>
                <figcaption className="thumbnail-title absolute text-lg -mt-16 self-center text-white px-4">
                    {
                        showTitle && <h1>{projectTitle}</h1>
                    }
                </figcaption>
            </figure>
        </div>
    );
}